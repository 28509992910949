import { render, staticRenderFns } from "./manage.vue?vue&type=template&id=5079f82e&scoped=true&"
import script from "./manage.vue?vue&type=script&lang=js&"
export * from "./manage.vue?vue&type=script&lang=js&"
import style0 from "./manage.vue?vue&type=style&index=0&id=5079f82e&prod&lang=less&scoped=true&"
import style1 from "./manage.vue?vue&type=style&index=1&id=5079f82e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../yiweb/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5079f82e",
  null
  
)

export default component.exports